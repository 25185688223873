<template>
  <div class="calculator-content">
    <h3>🤖Калькулятор стоимости:</h3>
    <div class="calculator-input">
      <input class="price-input" placeholder="250" v-model="originPrice">
      <p>💲</p>
    </div>
    <div class="calculator-result">
      <h4>{{ resultingData }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PriceCalculator',
  data() {
    return {
      originPrice: 0
    }
  },
  computed: {
    resultingData() {
      if (typeof this.originPrice === 'undefined' || !this.originPrice) return "";

      let resul = [];
      let originPriceRub = this.usdRate * this.originPrice
      resul.push("Стоимость товара: " + originPriceRub.toFixed(2) + "₽");
      let taxPrice = 0
      if (this.originPrice > 500) {
        taxPrice = (this.originPrice - 500) * 0.15 * this.usdRate
        resul.push("Налог: " + taxPrice.toFixed(2)+ "₽");
      }
      let commission = originPriceRub * 0.1
      resul.push("Комиссия: " + commission.toFixed(2)+ "₽");
      let total = originPriceRub + taxPrice + commission
      resul.push("Итого: " + total.toFixed(2)+ "₽");
      return resul.join("            ");
    },
    usdRate() {
      return 60 * 1.07
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.calculator-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calculator-input {
  display: flex;
  align-items: center;
}

.calculator-result {
  display: flex;
}

.price-input {
  border-style: none none solid none;
  width: 100px;
  height: 40px;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
