<template>
  <div class="content-title">
    <h3>SENDITY✈️</h3>
  </div>
  <div class="content-description">
    <h2>Доставка товара из США🇺🇸</h2>
    <p>Ваш товар будет выкуплен по курсу 60₽ за 1💲. При покупке товара дороже 500💲 нужно учитывать налог, который составляет 15% от суммы превышения.</p>
    <p>Доставка товара осуществляется на указанный Вами адрес🏠 в РФ. Стоимость доставки 2000₽ за 1кг</p>
    <p>Стоимость выкупа составляет 10% от цены товара</p>
    <div style="display: flex; justify-content: center; align-items: center">
      <h1 style="margin: 0">📮</h1>
      <a class="link" href="https://t.me/mazumisha">@mazumisha</a>
    </div>
  </div>
  <PriceCalculator/>
<!--  <div class="contact">-->
<!--    -->
<!--  </div>-->
  <div class="content-footer">
    <h2>🚚 with ❤️</h2>
  </div>
  <img class="main-icon" src="./assets/character.svg" height="400">
</template>

<script>
import PriceCalculator from './components/PriceCalculator.vue'

export default {
  name: 'App',
  components: {
    PriceCalculator
  }
}
</script>

<style>
body {
  height: 100vh;
}

.contact {
  padding-top: 5%;
}

.link {
  color: black;
  font-weight: bold;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.content-title {
  display: flex;
  font-size: 30px;
  padding-left: 7%;
  /*padding-top: 3%;*/
}

.content-footer {
  position: fixed;
  bottom: 0;
  width: 99%;
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
}

.content-description {
  padding-top: 40px;
}

.main-icon {
  position: absolute;
  left: 1%;
  bottom: 1%;
  z-index: -10;
}
</style>
